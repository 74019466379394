import InfoCards from "./InfoCards";
import { Box, Button, Typography } from "@mui/material";
import PriceCard from "./PriceCard";
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { useState, useEffect } from "react";
import UpdateOrderModal from "./UpdateOrderModal";
import { getQuoatationDetail } from "../../../utils/schemaExtraction";

const PurchaseOrderPannel = ({ data, getOrders, closeActionPannel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);

  const updateOrderState = [
    "Accepted",
    "In-progress",
    "OutOfDelivery",
    "Delivered",
  ];

  useEffect(() => {
    const { intercomeFields, billingFields, buyerInfo, deliveryFields } =
      getQuoatationDetail(data);

    const fieldsList = [
      {
        heading: data.transactionId,
        fields: intercomeFields,
      },
      {
        heading: "Buyer info",
        fields: buyerInfo,
      },
      {
        heading: "Delivery address",
        fields: deliveryFields,
      },
      {
        heading: "Billing address",
        fields: billingFields,
      },
    ];

    setFieldsData(fieldsList);
  }, [data]);

  const onAcceptPurchaseOrder = () => {
    setIsLoading(true);
    const url = `/api/v1/orders/sendConfirm/${data.transactionId}`;
    postCall(url)
      .then((data) => {
        cogoToast.success("Confirm request sent");
        getOrders();
      })
      .catch((err) => {
        cogoToast.error(
          "Error while sending Confirm request ",
          err?.response?.status
        );
      })
      .finally(() => {
        closeActionPannel();
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        gap: "10px",
      }}
    >
      <UpdateOrderModal
        open={isUpdateModalOpen}
        onClose={() => {
          setIsUpdateModalOpen(false);
          closeActionPannel();
        }}
        orderId={data.orderId}
        transactionId={data.transactionId}
        getOrders={getOrders}
      />
      <Typography variant="h5">Purchase Order</Typography>
      {fieldsData?.map((field) => (
        <InfoCards heading={field.heading} fields={field.fields} />
      ))}
      <PriceCard
        fields={[
          { label: "Delivery Charge", value: "0.0" },
          { label: "Packaging Charge", value: "0.0" },
          { label: "Discount", value: "0.0" },
          { label: "Total", value: "0.0" },
        ]}
      />
      {updateOrderState.includes(data.state) ? (
        <Button
          onClick={() => setIsUpdateModalOpen(true)}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      ) : data.state === "Completed" ? (
        <></>
      ) : (
        <>
          <Button
            disabled={isLoading}
            onClick={onAcceptPurchaseOrder}
            variant="contained"
            color="primary"
          >
            Accept
          </Button>
          <Button disabled={isLoading} variant="contained" color="secondary">
            Reject
          </Button>
        </>
      )}
    </Box>
  );
};

export default PurchaseOrderPannel;
