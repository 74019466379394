// MuiCard.js

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const InfoCards = ({ heading, fields }) => {
  return (
    <Card
      sx={{
        boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
      }}
    >
      <CardContent>
        <Typography fontWeight={"bold"} variant="subtitle1" component="div">
          {heading}
        </Typography>
        {fields?.map((field, index) => (
          <div key={index}>
            <Typography variant="subtitle2" color="textSecondary">
              {field.label} {field.value}
            </Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default InfoCards;
