import { useState, Fragment } from "react";
import ActionModal from "./ActionModal.jsx";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import ChatIcon from "@mui/icons-material/Chat";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QuoatationPannel from "./QuoatationPannel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getFullAddress, getFulfillmentData } from "./../../../utils/orders.js";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { statusMapping } from "../../../utils/index.js";
import ActionPannel from "./ActionPannel.jsx";
import MySpace from "./MySpace.jsx";
import { getChatRoomUrl } from "../../../utils/schemaExtraction.js";
import PurchaseOrderPannel from "./PurchaseOrderPannel.jsx";
import cogoToast from "cogo-toast";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});

export default function InventoryTable(props) {
  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;
  const navigate = useNavigate();
  const [isActionPannel, setIsActionPannelActive] = useState(false);
  const [actionPannelType, setActionPannelType] = useState("");
  const [chatRoomUrl, setChatRoomUrl] = useState("");
  const [selectOrderData, setSelectOrderData] = useState({});

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  // const ThreeDotsMenu = (props) => {
  //   const [anchorEl, setAnchorEl] = useState(null);

  //   function handleMenuClick(data) {}

  //   const handleClick = (e) => {
  //     setAnchorEl(e.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const { data } = props;

  //   return (
  //     <Fragment>
  //       <Button onClick={(e) => handleClick(e)}>
  //         <MoreVertIcon />
  //       </Button>
  //       <Menu
  //         id="card-actions-menu"
  //         anchorEl={anchorEl}
  //         keepMounted
  //         open={Boolean(anchorEl)}
  //         onClose={handleClose}
  //       >
  //         <MenuItem
  //           onClick={() => {
  //             navigate(`/application/orders/${props.row.attributes.order_id}`);
  //           }}
  //         >
  //           Order Details
  //         </MenuItem>
  //         <MenuItem onClick={() => handleMenuClick(data.Val1)}>
  //           Edit Order
  //         </MenuItem>
  //       </Menu>
  //     </Fragment>
  //   );
  // };
  const getActionPannelChildren = () => {
    switch (actionPannelType) {
      case "MySpace":
        return <MySpace url={chatRoomUrl} />;
      case "QuoatationRequest":
        return (
          <QuoatationPannel
            data={selectOrderData}
            getOrders={props.getOrders}
            closeActionPannel={() => setIsActionPannelActive(false)}
          />
        );
      case "PurchaseOrder":
        return (
          <PurchaseOrderPannel
            data={selectOrderData}
            getOrders={props.getOrders}
            closeActionPannel={() => setIsActionPannelActive(false)}
          />
        );
      default:
        return <></>;
    }
  };

  const getPaymentTypeFromLogs = (data) => {
    for (const log of data?.logs) {
      if ("select" in log) {
        return log["select"].message.order.payments[0]?.type;
      }
    }
  };

  const renderColumn = (row, column) => {
    const value = row[column.id];
    const payment = row["payment"];
    const delivery_info = getFulfillmentData(row["fulfillments"], "Delivery");
    const ordered_items = row.items;

    switch (column.id) {
      case "transactionID":
        return (
          <>
            <span>{row.transactionId}</span>
          </>
        );
      case "createdAt":
        return (
          <>
            <span>{convertDateInStandardFormat(value)}</span>
          </>
        );
      case "productName":
        return (
          <>
            <span>{row?.items[0]?.details?.productName}</span>
          </>
        );
      case "state":
        return (
          <div>
            <span className="mr-2">{statusMapping(value)}</span>
          </div>
        );

      case "payment_type":
        return (
          <div>
            <span>
              {(payment && payment[0]?.type) || getPaymentTypeFromLogs(row)}
            </span>
            <br />
          </div>
        );
      case "provider_name":
        return (
          <div>
            <span>{row?.organization?.name}</span>
            <br />
          </div>
        );
      case "total_amt":
        return (
          <div>
            <span>
              ₹
              {row?.quote?.price?.value ||
                row?.count * row?.items[0]?.details?.MRP}
            </span>
            <br />
          </div>
        );
      case "order_items":
        return (
          <div>
            x{ordered_items.length}
            {/* {ordered_items.map((item, idx) => (
              <span>{`${item.attributes.product.data.attributes.name} ${
                idx !== ordered_items.length - 1 ? "," : ""
              } `}</span>
            ))} */}
            <br />
          </div>
        );
      // case "delivery_info":
      //   return (
      //     <div style={{ textTransform: "capitalize" }}>
      //       <span>
      //         <p>{getFullAddress(delivery_info?.end?.location?.address)}</p>
      //       </span>
      //       <br />
      //     </div>
      //   );
      case "actionBtn":
        return (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <OpenInNewIcon
              onClick={() => {
                if (row.state === "ON_SELECT") {
                  cogoToast.info("Equiry accepted.");
                  return;
                }

                const quoatationRequestState = [
                  "INIT",
                  "ON_INIT",
                  "SELECT",
                  "ON_SELECT",
                ];
                const purchaseOrderState = [
                  "CONFIRM",
                  "ON_CONFIRM",
                  "Accepted",
                  "Packed",
                  "OutOfDelivery",
                  "Delivered",
                  "Completed",
                  "In-progress",
                ];

                if (quoatationRequestState.includes(row.state)) {
                  setActionPannelType("QuoatationRequest");
                } else if (purchaseOrderState.includes(row.state)) {
                  setActionPannelType("PurchaseOrder");
                }
                setSelectOrderData(row);
                setIsActionPannelActive(true);
              }}
            />
            <ChatIcon
              onClick={(e) => {
                const url = getChatRoomUrl(row);
                setActionPannelType("MySpace");
                setChatRoomUrl(url);
                setIsActionPannelActive(true);
              }}
            />
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="font-medium"
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => {
              return (
                <TableRow
                  style={{ cursor: "pointer" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  // onClick={() => {
                  //   navigate(`/application/orders/${row?._id}`);
                  // }}
                >
                  {props.columns.map((column, idx) => {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {renderColumn(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <ActionPannel
        open={isActionPannel}
        onClose={() => setIsActionPannelActive(false)}
      >
        <Box sx={{ width: "450px" }}>{getActionPannelChildren()}</Box>
      </ActionPannel>
    </Paper>
  );
}
