import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { TextField, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";

const QuoatationUpdate = ({ data, setIsDisabled, updateQuoatationData }) => {
  const [totalValue, setTotalValue] = useState();
  const [discount, setDiscount] = useState();
  const [discountedValue, setDiscountedValue] = useState();
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState();
  const [tat, setTat] = useState();
  const [itemCustom, setItemCustom] = useState();
  const [packageDetail, setPackageDetail] = useState();

  useEffect(() => {
    if (price && quantity && tat) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setTotalValue(price * quantity);

    const quoatationData = {
      price,
      quantity,
      tat,
      discount,
      deliveryCharge,
      itemCustom,
      packageDetail,
    };

    updateQuoatationData(quoatationData);
  }, [
    price,
    quantity,
    tat,
    discount,
    deliveryCharge,
    itemCustom,
    packageDetail,
  ]);

  useEffect(() => {
    if (discount === 0) return;

    setDiscountedValue(totalValue - totalValue * (discount / 100));
  }, [discount, totalValue]);

  useEffect(() => {
    setPrice(data.items[0]?.details?.MRP);
    setQuantity(data.count);
  }, []);

  return (
    <Card
      sx={{
        boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={data.items[0]?.details?.images[0]}
          alt="Product"
          style={{ height: "50px", marginTop: "8px" }}
        />
        <Typography variant="subtitle1" color="primary" fontWeight="bold">
          {data.items[0]?.details?.productName}
        </Typography>
      </Box>

      <TextField
        label="Item Customization"
        variant="outlined"
        fullWidth
        margin="normal"
        value={itemCustom}
        onChange={(e) => setItemCustom(e.target.value)}
      />

      <TextField
        label="Packaging Details"
        variant="outlined"
        fullWidth
        margin="normal"
        value={packageDetail}
        onChange={(e) => setPackageDetail(e.target.value)}
      />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ width: "auto" }}
          label="Price *"
          variant="outlined"
          margin="normal"
          defaultValue={data.items[0]?.details?.MRP}
          onChange={(e) => setPrice(e.target.value)}
        />
        <Typography variant="subtitle2" fontWeight={"bold"}>
          X
        </Typography>
        <TextField
          sx={{ width: "auto" }}
          label="Quantity *"
          variant="outlined"
          margin="normal"
          defaultValue={data.count}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <Typography variant="subtitle2" fontWeight={"bold"}>
          =
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={"bold"}
        >{`₹${totalValue}`}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ width: "39%" }}
          label="Discount %"
          variant="outlined"
          margin="normal"
          type="number"
          onChange={(e) => setDiscount(e.target.value)}
        />
        {discount === 0 || !discount ? (
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {"Total: ₹" + totalValue}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >{`Total: `}</Typography>
            <Typography
              sx={{ textDecoration: "line-through" }}
              variant="subtitle2"
              fontWeight={"bold"}
            >
              {`₹${totalValue}`}
            </Typography>
            <Typography
              sx={{ color: "green" }}
              variant="subtitle2"
              fontWeight={"bold"}
            >
              {`₹${discountedValue}`}
            </Typography>
          </Box>
        )}
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TextField
          sx={{ width: "45%" }}
          label="Delivery Charge"
          variant="outlined"
          margin="normal"
          value={deliveryCharge}
          onChange={(e) => setDeliveryCharge(e.target.value)}
        />
        <TextField
          sx={{ width: "45%" }}
          label="Expected TAT *"
          variant="outlined"
          margin="normal"
          value={tat}
          onChange={(e) => setTat(e.target.value)}
        />
      </Box>
    </Card>
  );
};

export default QuoatationUpdate;
