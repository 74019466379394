import InfoCards from "./InfoCards";
import { Box, Button, Typography } from "@mui/material";
import PriceCard from "./PriceCard";
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";
import QuoatationUpdate from "./QuoatationUpdate";
import { getQuoatationDetail } from "../../../utils/schemaExtraction";

const QuoatationPannel = ({ data, getOrders, closeActionPannel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [isAcceptButtonDisabled, setIsAcceptButtonDisabled] = useState(true);
  const [quoatationData, setQuoatationData] = useState({});

  const onAcceptQuoataion = () => {
    setIsLoading(true);
    const url = `/api/v1/orders/sendInit/${data.transactionId}`;

    postCall(url, quoatationData)
      .then((data) => {
        cogoToast.success("Quoatation request sent");
        getOrders();
      })
      .catch((err) => {
        cogoToast.error(
          "Error while sending quoatation request ",
          err?.response?.status
        );
      })
      .finally(() => {
        closeActionPannel();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const { intercomeFields, billingFields, buyerInfo, deliveryFields } =
      getQuoatationDetail(data);

    const fieldsList = [
      {
        heading: data.transactionId,
        fields: intercomeFields,
      },
      {
        heading: "Buyer info",
        fields: buyerInfo,
      },
      {
        heading: "Delivery address",
        fields: deliveryFields,
      },
      {
        heading: "Billing address",
        fields: billingFields,
      },
    ];

    setFieldsData(fieldsList);
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        gap: "10px",
      }}
    >
      <Typography variant="h5">Quoataion Request</Typography>
      {fieldsData?.map((field) => (
        <InfoCards heading={field.heading} fields={field.fields} />
      ))}
      <QuoatationUpdate
        data={data}
        setIsDisabled={(value) => setIsAcceptButtonDisabled(value)}
        updateQuoatationData={(data) =>
          setQuoatationData((preValue) => {
            return { ...preValue, ...data };
          })
        }
      />
      <PriceCard
        fields={[
          { label: "Delivery Charge", value: "0.0" },
          { label: "Packaging Charge", value: "0.0" },
          { label: "Discount", value: "0.0" },
          { label: "Total", value: "0.0" },
        ]}
      />
      <Button
        disabled={
          isLoading || data.state === "ON_INIT" || isAcceptButtonDisabled
        }
        onClick={onAcceptQuoataion}
        variant="contained"
        color="primary"
      >
        {data.state === "ON_INIT" ? "Quoatation Accepted" : "Accept"}
      </Button>
      <Button
        disabled={isLoading || data.state === "ON_INIT"}
        variant="contained"
        color="secondary"
      >
        Reject
      </Button>
    </Box>
  );
};

export default QuoatationPannel;
