export function evalQueryString(currentQueryString, params) {
  const currentParams = new URLSearchParams(currentQueryString);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      if (Array.isArray(params[key]) || typeof params[key] === "object") {
        currentParams.set(key, JSON.stringify(params[key]));
      } else {
        currentParams.set(key, params[key]);
      }
    } else if (currentParams.has(key)) {
      currentParams.delete(key);
    }
  });

  const queryItems = new Array();
  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of currentParams.entries()) {
    if (value) {
      queryItems.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }
  return queryItems.join("&");
}

export function statusMapping(status) {
  switch (status) {
    case "ON_SELECT":
      return "Enquire Accepted";
    case "INIT":
      return "Quoatation Recieved";
    case "ON_INIT":
      return "Quoatation Accepted";
    case "CONFIRM":
      return "Purchase Order Recieved";
    default:
      return status;
  }
}
