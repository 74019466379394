import Paper from "@mui/material/Paper";

const MySpace = ({ url }) => {
  return (
    <Paper
      sx={{
        margin: "0px",
        padding: "0px",
        position: "absolute",
        width: "400px",
        height: "100vh",
        zIndex: 1200,
        top: 0,
        left: 0,
      }}
    >
      <iframe
        frameborder="0"
        // style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
        height="100%"
        width="100%"
        src={url}
      ></iframe>
    </Paper>
  );
};

export default MySpace;
