export default [
    {
      id: "accHolderName",
      title: "Account Holder Name",
      placeholder: "Enter Account Holder Name",
      type: "input",
      required: true,
    },
    {
      id: "accNumber",
      title: "Account Number",
      placeholder: "Enter Account Number",
      type: "input",
      maxLength: 17,
      required: true,
    },
    {
      id: "bankName",
      title: "Bank Name",
      placeholder: "Enter Bank Name",
      type: "input",
      required: true,
    },
    {
      id: "branchName",
      title: "Branch Name",
      placeholder: "Enter Branch Name",
      type: "input",
      required: true,
    },
    {
      id: "IFSC",
      title: "IFSC Code",
      placeholder: "Enter IFSC Code",
      type: "input",
      maxLength: 11,
      required: true,
    },
    {
      id: "cancelledCheque",
      title: "Cancelled Cheque",
      type: "upload",
      file_type: "cancelled_cheque",
      required: true,
    },
];