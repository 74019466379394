import { Card, CardContent, Box, Typography } from "@mui/material";

const PriceCard = ({ fields }) => {
  return (
    <Card
      sx={{
        boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
      }}
    >
      <CardContent>
        {fields?.map((field, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {field.label}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                :
              </Typography>
            </Box>
            <Typography variant="subtitle2" color="textSecondary">
              {field.value}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default PriceCard;
