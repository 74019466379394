import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import {
  LocalShipping,
  Close as CloseIcon,
  Inventory as InventoryIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import axios from "axios";
import { useEffect } from "react";
import { Body } from "node-fetch";

const statusOptions = [
  { label: "Picked up", icon: <InventoryIcon /> },
  { label: "In Transit", icon: <LocalShipping /> },
  { label: "Delivered", icon: <CheckCircleIcon /> },
];

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const UpdateOrderModal = ({
  open,
  onClose,
  orderId,
  getOrders,
  transactionId,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("Picked up");
  const [fileType, setFileType] = useState("");
  const [invoiceFileData, setInvoiceFileData] = useState();
  const [pickupFileData, setPickupFileData] = useState();
  const [deliveryFileData, setDeliveryFileData] = useState();
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabaled] = useState(true);
  const [invoiceRemark, setInvoiceRemark] = useState("");
  const [transitRemark, setTransitRemark] = useState("");
  const [deliveryRemark, setDeliveryRemark] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    switch (selectedStatus) {
      case "Picked up":
        if (
          invoiceFileData?.viewUrl &&
          pickupFileData?.viewUrl &&
          invoiceRemark.length
        ) {
          setIsUpdateButtonDisabaled(false);
        } else {
          setIsUpdateButtonDisabaled(true);
        }
        return;
      case "In Transit":
        if (transitRemark.length) {
          setIsUpdateButtonDisabaled(false);
        } else {
          setIsUpdateButtonDisabaled(true);
        }
        return;
      case "Delivered":
        if (deliveryFileData?.viewUrl && deliveryRemark.length) {
          setIsUpdateButtonDisabaled(false);
        } else {
          setIsUpdateButtonDisabaled(true);
        }
        return;
    }
  }, [
    selectedStatus,
    invoiceFileData,
    pickupFileData,
    invoiceRemark,
    transitRemark,
    deliveryFileData,
    deliveryRemark,
  ]);

  const handleUploadButtonClick = (type) => {
    setFileType(type);
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const allowedMaxSize = 5 * 1024 * 1024; // 5 MB in Bytes
    const file = e.target.files[0];

    if (!file) {
      cogoToast.error("Something went wrong.");
      inputRef.current.value = null;
      return;
    }

    if (file.size > allowedMaxSize) {
      cogoToast.warn("File size should be less than 2 MB");
      inputRef.current.value = null;
      return;
    }

    getSignUrl(file).then((urlData) => {
      const url = urlData.urls;

      axios(url, {
        method: "PUT",
        data: file,
        headers: {
          "Content-Type": file.type,
        },
      })
        .then((response) => {
          cogoToast.info("Document uploaded");

          switch (fileType) {
            case "Invoice":
              return setInvoiceFileData({
                viewUrl: urlData.viewUrl,
                placeHolder: file.name,
              });
            case "Pickup":
              return setPickupFileData({
                viewUrl: urlData.viewUrl,
                placeHolder: file.name,
              });
            case "Delivery":
              return setDeliveryFileData({
                viewUrl: urlData.viewUrl,
                placeHolder: file.name,
              });
            default:
              inputRef.current.value = null;
              cogoToast.error("Something went wrong.");
              return;
          }
        })
        .catch((err) => {
          console.log("Error while uploading file, ", err);
          cogoToast.error("Error while uploading file.");
        });
    });
  };

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const getRequestBody = () => {
    switch (selectedStatus) {
      case "Picked up":
        return {
          state: "In-progress",
          fullfilmentState: "Order-picked-up",
          timestamp: new Date(),
          invoice: {
            label: "Invoice",
            url: invoiceFileData.viewUrl,
          },
          pickup: {
            lable: "Proof of Pickup",
            url: pickupFileData.viewUrl,
          },
          remark: {
            label: "Remark",
            value: invoiceRemark,
          },
        };
      case "In Transit":
        return {
          state: "In-progress",
          fullfilmentState: "Out-for-delivery",
          timestamp: new Date(),
          remark: {
            label: "Remark",
            value: transitRemark,
          },
        };
      case "Delivered":
        return {
          state: "Completed",
          fullfilmentState: "Order-delivered",
          timestamp: new Date(),
          remark: {
            label: "Remark",
            value: deliveryRemark,
          },
          delivery: {
            label: "Proof of delivery",
            url: deliveryFileData.viewUrl,
          },
        };
    }
  };

  const handleUpdateClick = () => {
    setIsUpdateButtonDisabaled(true);
    const url = `api/v1/orders/${transactionId}/status`;
    const body = getRequestBody();

    postCall(url, body)
      .then(() => {
        cogoToast.success("Status updated");
        getOrders();
        onClose();
      })
      .catch((err) => {
        cogoToast.error("Error while sending request ", err?.response?.status);
      })
      .finally(() => {
        setIsUpdateButtonDisabaled(false);
      });
  };

  const getSignUrl = async (file) => {
    const file_type = file.type;
    const url = `/api/v1/upload/pdf`;
    const data = {
      fileName: file.name.replace(`\.${file_type}`, ""),
      fileType: file_type,
    };
    const res = await postCall(url, data);
    return res;
  };

  const renderSelectedItem = (selected) => {
    const selectedOption = statusOptions.find(
      (option) => option.label === selected
    );
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ListItemIcon style={{ marginRight: "8px" }}>
          {selectedOption.icon}
        </ListItemIcon>
        <Typography variant="inherit">{selectedOption.label}</Typography>
      </div>
    );
  };

  const getFields = () => {
    switch (selectedStatus) {
      case "Picked up":
        return (
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <InputLabel>Invoice</InputLabel>
            <Box
              sx={{
                boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                minHeight: "56.5px",
              }}
            >
              <Typography>
                {invoiceFileData?.placeHolder || "Upload Invoice"}
              </Typography>
              {invoiceFileData?.placeHolder ? (
                <DeleteIcon
                  onClick={() => {
                    inputRef.current.value = null;
                    setInvoiceFileData(null);
                  }}
                />
              ) : (
                <Button
                  onClick={() => handleUploadButtonClick("Invoice")}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              )}
            </Box>
            <InputLabel>Proof of pickup</InputLabel>
            <Box
              sx={{
                boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                minHeight: "56.5px",
              }}
            >
              <Typography>
                {pickupFileData?.placeHolder || "Upload proof of pickup"}
              </Typography>
              {pickupFileData?.placeHolder ? (
                <DeleteIcon
                  onClick={() => {
                    inputRef.current.value = null;
                    setPickupFileData(null);
                  }}
                />
              ) : (
                <Button
                  onClick={() => handleUploadButtonClick("Pickup")}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              )}
            </Box>
            <TextField
              label="Remark"
              variant="outlined"
              fullWidth
              margin="normal"
              value={invoiceRemark}
              onChange={(e) => setInvoiceRemark(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
        );
      case "In Transit":
        return (
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <TextField
              label="Remark"
              variant="outlined"
              fullWidth
              margin="normal"
              value={transitRemark}
              onChange={(e) => setTransitRemark(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
        );
      case "Delivered":
        return (
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <InputLabel>Proof of delivery</InputLabel>
            <Box
              sx={{
                boxShadow: "2px 0px 6px 1px rgba(0, 0, 0, 0.3)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                minHeight: "56.5px",
              }}
            >
              <Typography>
                {deliveryFileData?.placeHolder || "Upload proof of delivery"}
              </Typography>
              {deliveryFileData?.placeHolder ? (
                <DeleteIcon
                  onClick={() => {
                    inputRef.current.value = null;
                    setDeliveryFileData(null);
                  }}
                />
              ) : (
                <Button
                  onClick={() => handleUploadButtonClick("Delivery")}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              )}
            </Box>
            <TextField
              label="Remark"
              variant="outlined"
              fullWidth
              margin="normal"
              value={deliveryRemark}
              onChange={(e) => setDeliveryRemark(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={modalStyle}
      aria-labelledby="update-modal"
    >
      <DialogContent
        sx={{
          width: "400px",
          padding: "16px",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography textAlign={"center"} variant="h6">
            Update Order Status
          </Typography>
          <Typography textAlign={"center"} variant="subtitle2">
            {orderId}
          </Typography>
        </Box>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            value={selectedStatus}
            onChange={handleChange}
            label="Status"
            renderValue={() => renderSelectedItem(selectedStatus)}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <Typography variant="inherit">{option.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {getFields()}

        <Button
          sx={{ width: "100%" }}
          variant="contained"
          color="primary"
          disabled={isUpdateButtonDisabled}
          onClick={handleUpdateClick}
        >
          Update Status
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateOrderModal;
