/**
 *
 * @param {Object} data Orders object
 * @returns My Spaces URL from the tags field of the ONDC Payload "select"
 */
export const getChatRoomUrl = (data) => {
  for (const log of data?.logs) {
    if ("select" in log) {
      for (const tag of log["select"].message.order.tags) {
        if (tag?.descriptor?.code === "COMM_CHANNEL") {
          for (const item of tag?.list) {
            if (item?.descriptor?.code === "chat_url") {
              return item?.value;
            }
          }
        }
      }
    }
  }
};

export const getQuoatationDetail = (data) => {
  for (const log of data?.logs) {
    if ("init" in log) {
      for (const tag of log["init"].message.order.fulfillments[0].tags) {
        if (tag?.descriptor?.code === "DELIVERY_TERMS") {
          let intercomeFields = [];
          let deliveryFields = [];
          const buyerInfo = [
            {
              label: log["init"].message.order.billing.name,
              value: "",
              type: "Bold",
            },
            {
              label: "Tax Id: ",
              value: log["init"].message.order.billing.tax_id,
            },
          ];
          let billingFields = [
            {
              label: log["init"].message.order.billing.name,
              value: "",
            },
            {
              label: `${log["init"].message.order.billing.address},`,
              value: log["init"].message.order.billing.city.name,
            },
            {
              label: log["init"].message.order.billing.phone,
              value: "",
            },
          ];

          for (const item of log["init"].message.order.fulfillments[0].stops) {
            if (item.type === "end") {
              deliveryFields.push(
                {
                  label: `${item.location.address},`,
                  value: item.location.city.name,
                },
                {
                  label: item.contact.phone,
                  value: "",
                }
              );
            }
          }

          for (const item of tag?.list) {
            if (item.descriptor.code === "INCOTERMS") {
              intercomeFields.push({ label: "Intercome: ", value: item.value });
            } else if (item.descriptor.code === "NAMED_PLACE_OF_DELIVERY") {
              intercomeFields.push({
                label: "Named place of delivery: ",
                value: item.value,
              });
            }
          }
          intercomeFields.push({
            label: new Date(log["init"].context.timestamp).toGMTString(
              "en-us",
              {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            ),
            value: "",
          });
          return { intercomeFields, billingFields, buyerInfo, deliveryFields };
        }
      }
    }
  }
};
