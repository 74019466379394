export default [
    {
      id: "email",
      title: "Email",
      placeholder: "Enter Provider Email Address",
      type: "input",
      email: true,
      required: true,
    },
    {
      id: "mobile",
      title: "Mobile Number",
      placeholder: "Enter Provider Mobile Number",
      type: "input",
      mobile: true,
      maxLength: 10,
      required: true,
    },
    {
      id: "name",
      title: "Name",
      placeholder: "Enter Provider Name",
      type: "input",
      required: true,
    },
    {
      id: "password",
      title: "Password",
      placeholder: "Enter Password",
      type: "input",
      required: true,
      password: true
    },
];