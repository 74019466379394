// MuiSidebar.js

import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

const drawerWidth = "auto";

const ActionPannel = ({ open, onClose, children }) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          width: drawerWidth,
          maxWidth: 400,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        {children}
      </Drawer>
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          onClick={onClose}
        />
      )}
    </>
  );
};

export default ActionPannel;
